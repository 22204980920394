'use es6';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { equals, fromString, getBoundedDate, isValid, nextDay, prevDay, toFormattedString } from '../../core/SimpleDate';
import SyntheticEvent from '../../core/SyntheticEvent';
import { SimpleDateType } from '../../types/SimpleDateTypes';
import { getPageFromDate } from './calendarUtils';
class AbstractCalendar extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = evt => {
      const {
        format,
        onChange,
        value
      } = this.props;
      this.setState({
        inputValue: evt.target.value
      });

      // Update date value as the user types
      const userInput = evt.target.value.trim();
      if (userInput === '') {
        onChange(SyntheticEvent(null));
      } else {
        const userInputDate = fromString(userInput, format);
        if (userInputDate !== null && !equals(userInputDate, value)) {
          this.updateDate(userInputDate);
        }
      }
    };
    this.handleKeyDown = evt => {
      const {
        onOpenChange,
        open,
        originDate,
        today,
        value
      } = this.props;
      const {
        key
      } = evt;
      clearTimeout(this._keyUpTimeout);
      this._pressedKey = key;

      // The "keyboard cursor" is implicitly on the current selection, or today's date if clear.
      const computedCurrentDate = isValid(value) ? value : originDate || today;
      if (key === 'ArrowUp') {
        evt.preventDefault();
        const newValue = nextDay(computedCurrentDate);
        this.updateDate(newValue);
      } else if (key === 'ArrowDown') {
        evt.preventDefault();
        const newValue = prevDay(computedCurrentDate);
        this.updateDate(newValue);
      } else if (key === 'Escape') {
        if (!open || !onOpenChange) return;
        evt.preventDefault();
        evt.stopPropagation();
        onOpenChange(SyntheticEvent(false));
      }
    };
    this.handleKeyUp = () => {
      // The delay here should ensure that onKeyUp resolves after onChange, even under IE11 (#7331).
      clearTimeout(this._keyUpTimeout);
      this._keyUpTimeout = setTimeout(() => {
        this._pressedKey = null;
      }, 0);
    };
    this.handleBlur = () => {
      const {
        format,
        onFocusedValueChange,
        value
      } = this.props;
      this.updateInput(value, format);
      this._pressedKey = null;
      onFocusedValueChange(SyntheticEvent(null));
    };
    this.state = {
      inputValue: toFormattedString(props.value, props.format)
    };
    this._keyUpTimeout = 0;
    this._pressedKey = null;
  }
  componentDidUpdate(prevProps) {
    const {
      calendarPage,
      onCalendarPageChange,
      onOpenChange,
      open,
      value,
      format
    } = prevProps;
    if (!equals(value, this.props.value)) {
      // Flip to the calendar page that includes the selected date
      const newCalendarPage = getPageFromDate(this.props.value);
      if (!equals(newCalendarPage, calendarPage)) {
        onCalendarPageChange(SyntheticEvent(newCalendarPage));
      }

      // `pressedKey` tells us whether this change was caused by the mouse or the keyboard
      const shouldClosePicker = this._pressedKey === null;
      const shouldUpdateInput = this._pressedKey === null || this._pressedKey === 'ArrowUp' || this._pressedKey === 'ArrowDown';
      if (shouldClosePicker && open) {
        onOpenChange(SyntheticEvent(false));
      }
      if (shouldUpdateInput) {
        this.updateInput(this.props.value, this.props.format);
      }
    } else if (format !== this.props.format) {
      this.updateInput(this.props.value, this.props.format);
    }
  }
  componentWillUnmount() {
    clearTimeout(this._keyUpTimeout);
  }
  updateInput(value, format) {
    const {
      inputValue
    } = this.state;
    const formattedDate = toFormattedString(value, format);
    if (formattedDate !== inputValue) {
      this.setState({
        inputValue: formattedDate
      });
    }
  }
  updateDate(newValue) {
    const {
      calendarPage,
      disabledValues,
      max,
      maxValue,
      min,
      minValue,
      onCalendarPageChange,
      onChange,
      onFocusedValueChange,
      onOpenChange
    } = this.props;
    if (!calendarPage || newValue.month !== calendarPage.month) {
      onCalendarPageChange(SyntheticEvent({
        year: newValue.year,
        month: newValue.month
      }));
    }
    if (!equals(newValue, getBoundedDate(newValue, min || minValue, max || maxValue)) || disabledValues && disabledValues.some(disabledValue => equals(newValue, disabledValue))) {
      // Show tooltip on disabled date
      onFocusedValueChange(SyntheticEvent(newValue));
      onOpenChange(SyntheticEvent(true));
      return;
    }
    onFocusedValueChange(SyntheticEvent(null));
    onChange(SyntheticEvent(newValue));
  }
  render() {
    const {
      children
    } = this.props;
    const {
      inputValue
    } = this.state;
    return children({
      onBlur: this.handleBlur,
      onInputChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      onKeyUp: this.handleKeyUp,
      inputValue
    });
  }
}
if (process.env.NODE_ENV !== 'production') {
  AbstractCalendar.propTypes = {
    calendarPage: PropTypes.shape({
      year: PropTypes.number.isRequired,
      month: PropTypes.number.isRequired
    }),
    children: PropTypes.func.isRequired,
    disabledValues: PropTypes.arrayOf(SimpleDateType),
    format: PropTypes.oneOf(['L', 'YYYY-MM-DD', 'LL', 'll']).isRequired,
    max: SimpleDateType,
    maxValue: SimpleDateType,
    min: SimpleDateType,
    minValue: SimpleDateType,
    onCalendarPageChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocusedValueChange: PropTypes.func.isRequired,
    onOpenChange: PropTypes.func,
    open: PropTypes.bool,
    originDate: SimpleDateType,
    today: SimpleDateType.isRequired,
    value: SimpleDateType
  };
}
AbstractCalendar.defaultProps = {
  format: 'L'
};
export default AbstractCalendar;